.contenitore-bottoni-destra {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: end;
    margin-top: 10px;
    box-sizing: border-box;
    margin-top: auto;
    padding-top: 20px;
    border: 'solid';
}

.contenitore-mappa-imprese {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 16px;
    grid-template-rows: 100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: fit-content;
}

.etichettaUno {
    max-width: 100%;
    grid-area: etichettaUno;
    width: 100%;
    box-sizing: border-box;
}
.etichettaDue {
    max-width: 100%;
    grid-area: etichettaDue;
    width: 100%;
    box-sizing: border-box;
}
.etichettaTre {
    max-width: 100%;
    grid-area: etichettaTre;
    width: 100%;
    box-sizing: border-box;
}
.elementoUno {
    max-width: 100%;
    grid-area: elementoUno;
    width: 100%;
    box-sizing: border-box;
}
.elementoDue {
    max-width: 100%;
    grid-area: elementoDue;
    width: 100%;
    box-sizing: border-box;
}
.elementoTre {
    max-width: 100%;
    grid-area: elementoTre;
    width: 100%;
    box-sizing: border-box;
}

.gridDueDue {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
        'etichettaUno etichettaDue'
        'elementoUno elementoDue';
    box-sizing: border-box !important;
    row-gap: 10px;
    column-gap: 20px;
    max-width: 1100px;
    margin: 0 auto;
    padding: 10px;
}
.gridTreDue {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
        'etichettaUno etichettaDue etichettaTre'
        'elementoUno elementoDue elementoTre';
    box-sizing: border-box !important;
    row-gap: 10px;
    column-gap: 20px;
    margin: 0 auto;
    padding: 10px;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .contenitore-mappa-imprese {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: max-content 1fr;
        max-height: 100%;
    }
}
@media screen and (min-width: 1200px) {
    .toolbar-post-login {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .gridDueDue {
        display: grid !important;
        grid-template-columns: 100% !important;
        grid-template-rows: max-content max-content max-content max-content !important;
        grid-template-areas:
            'etichettaUno'
            'elementoUno'
            'etichettaDue'
            'elementoDue'
            'etichettaTre'
            'elementoTre' !important;
    }
}
@media screen and (max-width: 992px) {
    .gridTreDue {
        display: grid !important;
        grid-template-columns: 100% !important;
        grid-template-rows: max-content max-content max-content max-content max-content max-content !important;
        grid-template-areas:
            'etichettaUno'
            'elementoUno'
            'etichettaDue'
            'elementoDue'
            'etichettaTre'
            'elementoTre' !important;
    }
}

.swiper-slide {
    box-sizing: border-box !important;
    max-width: 100% !important;
}
.swiper {
    box-sizing: border-box !important;
    max-width: 100% !important;
}

.swiper-button-next {
    color: #344767 !important;
    background-color: white !important;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    box-sizing: border-box !important;
}
.swiper-button-prev {
    color: #344767 !important;
    background-color: white !important;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    box-sizing: border-box !important;
}

.swiper-button-next .swiper-button-prev ::after {
    font-size: 5px !important;
}

.MuiCircularProgress-root {
    height: 100px !important;
    width: 100px !important;
    transform: rotate(90deg) !important;
}

.centrato {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    height: 100%;
}
.fine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    height: 100%;
}

.flex-verticale {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.flex-orizzontale {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.input-donazione {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border: none !important;
    color: #3f3f3f;
    align-items: flex-end;
    text-align: end;
}

.input-donazione:focus {
    outline: none;
}
#input-donazione-consumatore {
    display: flex;
    width: 100%;
    max-width: 180px;
    height: 53px;
    font-size: x-large;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border: none !important;
    align-items: flex-end;
    text-align: end;
    border: solid 1px !important;
    padding: 5px;
}

#input-donazione-consumatore:focus {
    outline: none;
}

.bottotne-donazione-consumatore {
    height: 50px;
    width: 100%;
    border-radius: 25px;
    cursor: pointer;
}

.immagine-progetto {
    box-sizing: border-box;
    object-fit: cover;
    height: 10vw;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .immagine-progetto {
        height: 15vw !important;
    }
}
@media screen and (max-width: 992px) {
    .immagine-progetto {
        height: 20vw !important;
    }
}
@media screen and (max-width: 768px) {
    .immagine-progetto {
        height: 25vw !important;
    }
}
@media screen and (max-width: 576px) {
    .immagine-progetto {
        height: 40vw !important;
    }
}

.contenitore-impatto {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content max-content 1fr;
    height: 100%;
    width: 100%;
    row-gap: 10px;
}

.input-donazione::-webkit-inner-spin-button,
.input-donazione::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.immagine-card-progetti {
    background-size: cover !important;
    backface-visibility: hidden;
    background-position: center !important;
}
